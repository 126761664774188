import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      jwt
    }
  }
`;

export const LOGIN_GOOGLE = gql`
  mutation loginGoogle($accessToken: String!) {
    loginGoogle(accessToken: $accessToken) {
      jwt
    }
  }
`;

export const LOGIN_FACEBOOK = gql`
  mutation loginFacebook($accessToken: String!) {
    loginFacebook(accessToken: $accessToken) {
      jwt
    }
  }
`;

export const ADD_MY_COURSES = gql`
  mutation addMyCourses($ids: [String]!) {
    addMyCourses(ids: $ids) {
      _id
      courses {
        courses {
          _id
          title
          university
        }
      }
    }
  }
`;

export const REMOVE_MY_COURSES = gql`
  mutation removeMyCourses($ids: [String]!) {
    removeMyCourses(ids: $ids) {
      _id
      courses {
        courses {
          _id
          title
          university
        }
      }
    }
  }
`;

export const SET_SUBSCRIPTION = gql`
  mutation setSubscription(
    $course: String!
    $newTeachings: Boolean
    $newQuestions: Boolean
    $newVideos: Boolean
    $newLinks: Boolean
    $newGroups: Boolean
  ) {
    setSubscription(
      course: $course
      newTeachings: $newTeachings
      newQuestions: $newQuestions
      newVideos: $newVideos
      newLinks: $newLinks
      newGroups: $newGroups
    ) {
      newTeachings
      newQuestions
      newVideos
      newLinks
      newGroups
    }
  }
`;

export const MARK_NOTIFICATIONS_AS_SEEN = gql`
  mutation markNotificationsAsSeen($ids: [String]!) {
    markNotificationsAsSeen(ids: $ids) {
      _id
      notifications {
        _id
        wasSeen
      }
    }
  }
`;

export const CLEAR_NOTIFICATIONS_COUNT = gql`
  mutation clearNotificationsCount {
    clearNotificationsCount {
      _id
      newNotificationsCount
    }
  }
`;

export const DELETE_NOTIFICATIONS = gql`
  mutation deleteNotifications($ids: [String]!, $limit: Int, $skip: Int) {
    deleteNotifications(ids: $ids) {
      _id
      notificationsCount
      notifications(limit: $limit, skip: $skip) {
        _id
        contentId
        notificationType
        course {
          _id
          title
          university
        }
        wasSeen
        createdAt
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($_id: String!) {
    deleteComment(_id: $_id)
  }
`;

export const DELETE_VIDEO_COMMENT = gql`
  mutation deleteComment($_id: String!) {
    deleteComment(_id: $_id) {
      _id
      contentVideos {
        _id
        comments {
          _id
        }
      }
    }
  }
`;

export const DELETE_VIDEO_COMMENT_ANSWER = gql`
  mutation deleteComment($_id: String!) {
    deleteComment(_id: $_id) {
      _id
      contentVideos {
        _id
        comments {
          _id
          comments {
            _id
          }
        }
      }
    }
  }
`;

export const ADD_CONTENT_VIDEOS = gql`
  mutation addContentVideo(
    $courseId: String!
    $url: String!
    $description: String
    $title: String
    $anonymous: Boolean
  ) {
    addContentVideo(
      courseId: $courseId
      url: $url
      description: $description
      anonymous: $anonymous
    ) {
      _id
      title
      contentVideos(title: $title, limit: 1) {
        contentVideosCount
        contentVideos {
          _id
          title
          contributor {
            username
          }
          url
          description
          videoThumbnailUrl
          youTubeChannelThumbnailURL
          youTubeChannelTitle
          youTubeChannelURL
          course
          createdAt
        }
      }
    }
  }
`;

export const REMOVE_CONTENT_VIDEO = gql`
  mutation removeContentVideo($contentVideoId: String!) {
    removeContentVideo(contentVideoId: $contentVideoId) {
      _id
      contentVideos(title: "") {
        contentVideos {
          _id
        }
      }
    }
  }
`;

export const LIKE_CONTENT_VIDEO = gql`
  mutation toggleLikeVideo($contentId: String!, $contentType: String!) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentVideos(_id: $contentId) {
        contentVideos {
          _id
          isLiked
          isDisliked
          dislikeCount
          likeCount
        }
      }
    }
  }
`;

export const LIKE_CONTENT_VIDEO_COMMENT = gql`
  mutation toggleLikeVideoComment(
    $contentId: String!
    $contentType: String!
    $videoId: String
  ) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentVideos(_id: $videoId) {
        contentVideos {
          _id
          comments(_id: $contentId) {
            comments {
              _id
              likeCount
              dislikeCount
              isLiked
              isDisliked
            }
          }
        }
      }
    }
  }
`;

export const LIKE_CONTENT_VIDEO_REPLY_COMMENT = gql`
  mutation toggleLikeVideoReplyComment(
    $contentId: String!
    $contentType: String!
    $videoId: String
    $commentId: String
  ) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentVideos(_id: $videoId) {
        contentVideos {
          _id
          comments(_id: $commentId) {
            comments {
              _id
              comments(_id: $contentId) {
                comments {
                  _id
                  likeCount
                  dislikeCount
                  isLiked
                  isDisliked
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LIKE_CONTENT_LINK = gql`
  mutation toggleLike($contentId: String!, $contentType: String!) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentLinks(_id: $contentId) {
        contentLinks {
          _id
          likeCount
          dislikeCount
          isLiked
          isDisliked
        }
      }
    }
  }
`;

export const DISLIKE_CONTENT_LINK = gql`
  mutation toggleDislike($contentId: String!, $contentType: String!) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      contentLinks(_id: $contentId) {
        contentLinks {
          _id
          likeCount
          dislikeCount
          isLiked
          isDisliked
        }
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation editUserProfile($file: Upload!) {
    editUserProfile(profilePicture: $file) {
      _id
      username
      profilePicture
    }
  }
`;

export const DISLIKE_CONTENT_VIDEO = gql`
  mutation toggleDislikeVideo($contentId: String!, $contentType: String!) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      contentVideos(_id: $contentId) {
        contentVideos {
          _id
          isLiked
          isDisliked
          dislikeCount
          likeCount
        }
      }
    }
  }
`;

export const DISLIKE_CONTENT_VIDEO_COMMENT = gql`
  mutation toggleDislike(
    $contentId: String!
    $contentType: String!
    $videoId: String
  ) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      contentVideos(_id: $videoId) {
        contentVideos {
          _id
          comments(_id: $contentId) {
            comments {
              _id
              likeCount
              dislikeCount
              isLiked
              isDisliked
            }
          }
        }
      }
    }
  }
`;

export const DISLIKE_CONTENT_VIDEO_REPLY_COMMENT = gql`
  mutation toggleDislike(
    $contentId: String!
    $contentType: String!
    $videoId: String
    $commentId: String
  ) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      contentVideos(_id: $videoId) {
        contentVideos {
          _id
          comments(_id: $commentId) {
            comments {
              _id
              comments(_id: $contentId) {
                comments {
                  _id
                  likeCount
                  dislikeCount
                  isLiked
                  isDisliked
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation postVideoComment(
    $contentId: String!
    $contentType: String!
    $comment: String!
  ) {
    postComment(
      contentId: $contentId
      contentType: $contentType
      comment: $comment
    ) {
      _id
      contentVideos(_id: $contentId) {
        contentVideos {
          _id
          comments(limit: 1) {
            commentsCount
            comments {
              _id
              contentId
              contentType
              contributor {
                username
                profilePicture
              }
              comment
              createdAt
              updatedAt
              likeCount
              dislikeCount
              isLiked
              isDisliked
              isContributor
              comments {
                commentsCount
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_COMMENT_REPLY = gql`
  mutation postCommentReply(
    $contentId: String!
    $videoId: String
    $contentType: String!
    $comment: String!
    $skip: Int
  ) {
    postComment(
      contentId: $contentId
      contentType: $contentType
      comment: $comment
    ) {
      contentVideos(_id: $videoId) {
        contentVideos {
          _id
          comments(_id: $contentId) {
            comments {
              _id
              comments(limit: 1, skip: $skip) {
                commentsCount
                comments {
                  _id
                  contentId
                  contentType
                  contributor {
                    username
                    profilePicture
                  }
                  comment
                  createdAt
                  updatedAt
                  likeCount
                  dislikeCount
                  isLiked
                  isDisliked
                  isContributor
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REPORT_ABUSE = gql`
  mutation reportAbuse(
    $contentId: String!
    $contentType: String!
    $reason: String!
    $subreason: String!
    $description: String!
  ) {
    reportAbuse(
      contentId: $contentId
      contentType: $contentType
      reason: $reason
      subreason: $subreason
      description: $description
    ) {
      _id
    }
  }
`;

export const EDIT_CONTENT_VIDEO = gql`
  mutation editContentVideo($_id: String!, $description: String) {
    editContentVideo(_id: $_id, description: $description) {
      _id
      contentVideos {
        contentVideos {
          _id
          description
        }
      }
    }
  }
`;

export const EDIT_COMMENT = gql`
  mutation editComment($_id: String!, $comment: String!) {
    editComment(_id: $_id, comment: $comment) {
      _id
      contentId
      comment
    }
  }
`;

export const ADD_CONTENT_LINK = gql`
  mutation addContentLink(
    $courseId: String!
    $title: String!
    $url: String!
    $description: String
  ) {
    addContentLink(
      courseId: $courseId
      title: $title
      url: $url
      description: $description
    ) {
      _id
      title
      contentLinks(limit: 1) {
        contentLinksCount
        contentLinks {
          _id
          course
          title
          contributor {
            username
          }
          isContributor
          url
          description
          thumbnail
          createdAt
          updatedAt
          likeCount
          dislikeCount
          isLiked
          isDisliked
        }
      }
    }
  }
`;

export const EDIT_CONTENT_LINK = gql`
  mutation editContentLink(
    $_id: String!
    $title: String
    $description: String
  ) {
    editContentLink(_id: $_id, title: $title, description: $description) {
      _id
      contentLinks(title: "", limit: 15) {
        contentLinks {
          _id
          title
          description
        }
      }
    }
  }
`;

export const REMOVE_CONTENT_LINK = gql`
  mutation removeContentLink($_id: String!) {
    removeContentLink(_id: $_id) {
      _id
      contentLinks {
        contentLinksCount
        contentLinks {
          _id
        }
      }
    }
  }
`;

export const ADD_CONTENT_QUESTION = gql`
  mutation addContentQuestion(
    $courseId: String!
    $title: String
    $question: String
  ) {
    addContentQuestion(
      courseId: $courseId
      title: $title
      question: $question
    ) {
      _id
      contentQuestions(limit: 1) {
        contentQuestionsCount
        contentQuestions {
          _id
          course
          title
          question
          hasCorrectAnswer
          contributor {
            username
          }
          isContributor
          createdAt
          updatedAt
          likeCount
          dislikeCount
          isLiked
          isDisliked
          answers {
            answersCount
          }
        }
      }
    }
  }
`;

export const EDIT_CONTENT_QUESTION = gql`
  mutation editContentQuestion(
    $_id: String!
    $title: String
    $question: String
  ) {
    editContentQuestion(_id: $_id, title: $title, question: $question) {
      _id
      contentQuestions(_id: $_id) {
        contentQuestions {
          _id
          title
          question
        }
      }
    }
  }
`;

export const REMOVE_CONTENT_QUESTION = gql`
  mutation removeContentQuestion($_id: String!) {
    removeContentQuestion(_id: $_id) {
      _id
    }
  }
`;

export const LIKE_CONTENT_QUESTION = gql`
  mutation toggleQuestionLike($contentId: String!, $contentType: String!) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentQuestions(_id: $contentId) {
        contentQuestions {
          _id
          likeCount
          dislikeCount
          isLiked
          isDisliked
        }
      }
    }
  }
`;

export const LIKE_CONTENT_ANSWER = gql`
  mutation toggleAnswerLike(
    $contentId: String!
    $contentType: String!
    $questionId: String
  ) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(_id: $contentId) {
            answers {
              _id
              likeCount
              dislikeCount
              isLiked
              isDisliked
            }
          }
        }
      }
    }
  }
`;

export const DISLIKE_CONTENT_ANSWER = gql`
  mutation toggleAnswerDislike(
    $contentId: String!
    $contentType: String!
    $questionId: String
  ) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(_id: $contentId) {
            answers {
              _id
              likeCount
              dislikeCount
              isLiked
              isDisliked
            }
          }
        }
      }
    }
  }
`;

export const LIKE_CONTENT_QUESTION_COMMENT = gql`
  mutation toggleCommentLike(
    $contentId: String!
    $contentType: String!
    $questionId: String
    $answerId: String
  ) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(_id: $answerId) {
            answers {
              _id
              comments(_id: $contentId) {
                comments {
                  _id
                  likeCount
                  dislikeCount
                  isLiked
                  isDisliked
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DISLIKE_CONTENT_QUESTION_COMMENT = gql`
  mutation toggleCommentDislike(
    $contentId: String!
    $contentType: String!
    $questionId: String
    $answerId: String
  ) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(_id: $answerId) {
            answers {
              _id
              comments(_id: $contentId) {
                comments {
                  _id
                  likeCount
                  dislikeCount
                  isLiked
                  isDisliked
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_CONTENT_ANSWER = gql`
  mutation addContentAnswer($questionId: String!, $answer: String!) {
    addContentAnswer(questionId: $questionId, answer: $answer) {
      _id
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(limit: 1) {
            answersCount
            answers {
              _id
              answer
              isCorrect
              createdAt
              contributor {
                username
                profilePicture
              }
              isContributor
              likeCount
              dislikeCount
              isLiked
              isDisliked
              comments {
                commentsCount
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_QUESTION_COMMENT = gql`
  mutation postComment(
    $questionId: String
    $contentId: String!
    $contentType: String!
    $comment: String!
  ) {
    postComment(
      contentId: $contentId
      contentType: $contentType
      comment: $comment
    ) {
      _id
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(_id: $contentId) {
            answers {
              _id
              comments(limit: 1) {
                commentsCount
                comments {
                  _id
                  contentId
                  contentType
                  contributor {
                    username
                    profilePicture
                  }
                  comment
                  createdAt
                  updatedAt
                  likeCount
                  dislikeCount
                  isLiked
                  isDisliked
                  isContributor
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SET_IS_ANSWER_CORRECT = gql`
  mutation setIsAnswerCorrect($_id: String!, $isCorrect: Boolean!) {
    setIsAnswerCorrect(_id: $_id, isCorrect: $isCorrect) {
      _id
      isCorrect
    }
  }
`;

export const EDIT_CONTENT_ANSWER = gql`
  mutation editContentAnswer(
    $_id: String!
    $answer: String!
    $questionId: String
  ) {
    editContentAnswer(_id: $_id, answer: $answer) {
      _id
      contentQuestions(_id: $questionId) {
        contentQuestions {
          _id
          answers(_id: $_id) {
            answers {
              _id
              answer
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CONTENT_ANSWER = gql`
  mutation deleteContentAnswer($answerId: String!) {
    deleteContentAnswer(_id: $answerId) {
      _id
    }
  }
`;

export const EDIT_COMMENT_ANSWER = gql`
  mutation editComment($_id: String!, $comment: String!) {
    editComment(_id: $_id, comment: $comment) {
      _id
      contentId
      comment
    }
  }
`;

export const DELETE_COMMENT_ANSWER = gql`
  mutation deleteComment($_id: String!) {
    deleteComment(_id: $_id)
  }
`;

export const EDIT_USER_NAME = gql`
  mutation editUserProfile($username: String) {
    editUserProfile(username: $username) {
      _id
      username
    }
  }
`;

export const EDIT_USER_PHOTO = gql`
  mutation editUserPhoto($avatarId: Int) {
    editUserProfile(avatarId: $avatarId) {
      _id
      profilePicture
    }
  }
`;

export const EDIT_USER_UNIVERSITIES = gql`
  mutation editUserProfile($universities: [String], $degreePrograms: [String]) {
    editUserProfile(
      universities: $universities
      degreePrograms: $degreePrograms
    ) {
      _id
      universities {
        name
      }
      degreePrograms {
        _id
        name
        university
      }
    }
  }
`;

export const ADD_MY_UNIVERSITY = gql`
  mutation editUserProfile($universities: [String]) {
    editUserProfile(universities: $universities) {
      _id
      universities {
        name
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation editUserPassword($oldPassword: String!, $newPassword: String!) {
    editUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const BECOME_TEACHER = gql`
  mutation becomeTeacher($course: String!, $price: Int!, $description: String) {
    becomeTeacher(course: $course, price: $price, description: $description) {
      _id
      teacher {
        _id
        username
        profilePicture
        avgResponseTime
      }
      isActive
      price
      description
      createdAt
      updatedAt
      likeCount
      dislikeCount
      isLiked
      isDisliked
      isTeacher
      scope
      acceptsRemote
      course {
        _id
        title
      }
    }
  }
`;

export const UNBECOME_TEACHER = gql`
  mutation unbecomeTeacher($course: String!, $isDeleted: Boolean) {
    unbecomeTeacher(course: $course, isDeleted: $isDeleted) {
      _id
      course {
        _id
      }
    }
  }
`;

export const EDIT_TEACHING = gql`
  mutation editTeaching(
    $id: String!
    $price: Int
    $description: String
    $acceptsRemote: Boolean
  ) {
    editTeaching(
      id: $id
      price: $price
      description: $description
      acceptsRemote: $acceptsRemote
    ) {
      _id
      price
      description
      acceptsRemote
    }
  }
`;

export const TOGGLE_TEACHING_LIKE = gql`
  mutation toggleLike($contentId: String!, $contentType: String!) {
    toggleLike(contentId: $contentId, contentType: $contentType) {
      teachings(_id: $contentId) {
        teachings {
          _id
          isLiked
          isDisliked
          dislikeCount
          likeCount
        }
      }
    }
  }
`;

export const TOGGLE_TEACHING_DISLIKE = gql`
  mutation toggleLike($contentId: String!, $contentType: String!) {
    toggleDislike(contentId: $contentId, contentType: $contentType) {
      teachings(_id: $contentId) {
        teachings {
          _id
          isLiked
          isDisliked
          dislikeCount
          likeCount
        }
      }
    }
  }
`;

export const ADD_COMMENT_FOR_TEACHER = gql`
  mutation postComment(
    $contentId: String!
    $contentType: String!
    $comment: String!
  ) {
    postComment(
      contentId: $contentId
      contentType: $contentType
      comment: $comment
    ) {
      title
    }
  }
`;

export const EDIT_COMMENT_FOR_TEACHER = gql`
  mutation editComment($_id: String!, $comment: String!) {
    editComment(_id: $_id, comment: $comment) {
      _id
      contentId
      comment
    }
  }
`;

export const DELETE_COMMENT_FOR_TEACHER = gql`
  mutation deleteComment($_id: String!) {
    deleteComment(_id: $_id)
  }
`;

export const REGISTER = gql`
  mutation register(
    $email: String!
    $username: String!
    $password: String!
    $universities: [String]!
    $degreePrograms: [String]!
    $areAGBsChecked: Boolean!
  ) {
    register(
      email: $email
      username: $username
      password: $password
      universities: $universities
      degreePrograms: $degreePrograms
      areAGBsChecked: $areAGBsChecked
    )
  }
`;

export const CONFIRM_REGISTRATION = gql`
  mutation confirmRegistration($token: String!) {
    confirmRegistration(token: $token) {
      jwt
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($email: String!) {
    resetUserPassword(email: $email)
  }
`;

export const CONFIRM_RESET_USER_PASSWORD = gql`
  mutation confirmResetUserPassword($token: String!, $newPassword: String!) {
    confirmResetUserPassword(token: $token, newPassword: $newPassword) {
      jwt
    }
  }
`;

export const POST_MESSAGE = gql`
  mutation postMessage(
    $type: String!
    $message: String!
    $receiver: String!
    $isTeachingRequest: Boolean
    $course: String
  ) {
    postMessage(
      type: $type
      message: $message
      receiver: $receiver
      isTeachingRequest: $isTeachingRequest
      course: $course
    ) {
      type
      title
      picture
      unreadMessagesCount
      messagesCount
      otherPrivateChatMember {
        _id
        username
        onHoliday
        profilePicture
      }
      _id
      messages {
        _id
        author {
          _id
          username
        }
        isAuthor
        message
        wasDelivered
        wasRead
        wasReadByMe
        createdAt
      }
    }
  }
`;

export const MARK_CHAT_MESSAGES_AS_READ = gql`
  mutation markChatMessagesAsRead($id: String!) {
    markChatMessagesAsRead(id: $id) {
      _id
      unreadMessagesCount
      messages {
        _id
        isAuthor
        message
        wasDelivered
        wasRead
        createdAt
      }
    }
  }
`;

export const DELETE_CHAT = gql`
  mutation deleteChat($id: String!) {
    deleteChat(id: $id) {
      _id
      chats(search: "") {
        chats {
          _id
          type
          title
          picture
          otherPrivateChatMember {
            profilePicture
            onHoliday
          }
          unreadMessagesCount
        }
      }
    }
  }
`;

export const ADD_GROUP = gql`
  mutation addGroup(
    $title: String!
    $courses: [String]!
    $maxMembers: Int!
    $description: String
    $picture: Upload
    $meetingTimes: [MeetingTimeInput]
  ) {
    addGroup(
      title: $title
      courses: $courses
      maxMembers: $maxMembers
      description: $description
      picture: $picture
      meetingTimes: $meetingTimes
    ) {
      _id
      isActive
      description
      title
      picture
    }
  }
`;

export const EDIT_GROUP = gql`
  mutation editGroup(
    $groupId: String!
    $title: String
    $courses: [String]
    $members: [String]
    $maxMembers: Int
    $admins: [String]
    $description: String
    $picture: Upload
    $meetingTimes: [MeetingTimeInput]
  ) {
    editGroup(
      _id: $groupId
      title: $title
      courses: $courses
      members: $members
      maxMembers: $maxMembers
      admins: $admins
      description: $description
      picture: $picture
      meetingTimes: $meetingTimes
    ) {
      _id
      isMember
      membersCount
      maxMembers
      description
      title
      meetingTimes {
        day
        time
      }
      isAdmin
      courses {
        _id
        title
        university
      }
      picture
    }
  }
`;

export const JOIN_OPEN_GROUP = gql`
  mutation joinOpenGroup($groupId: String!) {
    joinOpenGroup(groupId: $groupId) {
      _id
      isMember
      membersCount
    }
  }
`;

export const RESEND_VERIFICATION_MAIL = gql`
  mutation resendVerificationMail($email: String!) {
    resendVerificationMail(email: $email)
  }
`;

export const BECOME_ACTIVE_STUDENT = gql`
  mutation becomeActiveStudent(
    $courseId: String!
    $expiresAt: String!
    $description: String
    $limit: Int
  ) {
    becomeActiveStudent(
      course: $courseId
      expiresAt: $expiresAt
      description: $description
    ) {
      _id
      title
      university
      activeStudentsData(limit: $limit) {
        activeStudentsCount
        activeStudents {
          _id
          expiresAt
          description
          student {
            _id
            username
            profilePicture
            activeStudentIn {
              activeStudents {
                course {
                  _id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UNBECOME_ACTIVE_STUDENT = gql`
  mutation unbecomeActiveStudent($courseId: String!, $limit: Int) {
    unbecomeActiveStudent(course: $courseId) {
      _id
      activeStudentsData(limit: $limit) {
        activeStudentsCount
      }
    }
  }
`;

export const EDIT_ACTIVE_STUDENT = gql`
  mutation editActiveStudent(
    $courseId: String!
    $expiresAt: String
    $description: String
  ) {
    editActiveStudent(
      course: $courseId
      expiresAt: $expiresAt
      description: $description
    ) {
      _id
      expiresAt
      description
    }
  }
`;
