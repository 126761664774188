import { ArrowLeft } from "../../../../common/arrow";
import { NotificationComponent } from "../../../../notifications/notification-component";
import { PrimarySpinner } from "../../../../spinner/primary-spinner";
import settings from "../../../../../img/edit_icon.svg";
import React, { useRef } from "react";
import styled from "styled-components";
import { useIsMobile } from "../../../../../hooks/state-checkers/use-is-mobile";
import { FormattedMessage } from "react-intl";
import { useQuery, useApolloClient } from "@apollo/client";
import { NOTIFICATION_SUBSCRIPTION } from "../subscriptions";
import {
  GET_NOTIFICATIONS,
  GET_TEACHINGS,
} from "../../../../../apollo-client/queries";

export const HeaderNotificationsBox = ({
  setIsNotificationsMenuOpen,
  setIsEmailMenuOpen,
}) => {
  const isMobile = useIsMobile();
  const client = useApolloClient();

  const {
    data: dataNotifications,
    loading: loadingNotifications,
    error: errorNotifications,
    fetchMore: fetchMoreNotifications,
    subscribeToMore: subscribeToMoreNotifications,
  } = useQuery(GET_NOTIFICATIONS, {
    variables: { limit: 15 },
  });

  subscribeToMoreNotifications({
    document: NOTIFICATION_SUBSCRIPTION,
    updateQuery: async (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;

      const newNotification = subscriptionData.data.newNotification;

      if (newNotification.notificationType === "newTeaching") {
        await client.refetchQueries({ include: [GET_TEACHINGS] });
      }

      return Object.assign({}, prev, {
        user: {
          ...prev.user,
          notifications: [newNotification, ...prev.user.notifications],
        },
      });
    },
  });

  const scrollRef = useRef(null);

  const handleScroll = async () => {
    const { current } = scrollRef;
    if (
      !loadingNotifications &&
      current.scrollTop + current.clientHeight >= current.scrollHeight &&
      dataNotifications.user.notifications.length <
        dataNotifications.user.notificationsCount
    ) {
      await fetchMoreNotifications({
        variables: {
          limit: 15,
          skip: dataNotifications.user.notifications.length,
        },
      });
    }
  };

  return (
    <NotificationBox>
      <HeadlineBox>
        {isMobile && (
          <ArrowLeft onClick={() => setIsNotificationsMenuOpen(false)} />
        )}
        <Headline>
          <FormattedMessage id="abo-notifications.notifications" />
        </Headline>
      </HeadlineBox>
      <NotificationContainer ref={scrollRef} onScroll={handleScroll}>
        {!errorNotifications &&
          dataNotifications &&
          dataNotifications?.user?.notifications?.map(
            (notification, i, notifications) => (
              <NotificationComponent
                key={"notification_" + i}
                notification={notification}
                prevNotification={notifications[i - 1]}
                setIsNotificationsMenuOpen={setIsNotificationsMenuOpen}
                skip={dataNotifications.user.notifications.length}
              />
            )
          )}
        {loadingNotifications && (
          <SpinnerWrapperFetchMore>
            <PrimarySpinner />
          </SpinnerWrapperFetchMore>
        )}
      </NotificationContainer>
      {loadingNotifications && (
        <SpinnerWrapper>
          <PrimarySpinner />
        </SpinnerWrapper>
      )}
      {!errorNotifications &&
        dataNotifications?.user?.notifications?.length === 0 && (
          <CenterText>
            Abonniere Module komplett, oder Teilbereiche, oder stelle Fragen und
            lass sie beantworten
          </CenterText>
        )}
      <EmailSettingsBox onClick={() => setIsEmailMenuOpen(true)}>
        <SettingsIcon src={settings}></SettingsIcon>
        <FormattedMessage id="notifications.emailSettings" />
      </EmailSettingsBox>
    </NotificationBox>
  );
};

const HeadlineBox = styled.div`
  display: flex;
  padding-left: 10px;
`;

const Headline = styled.div`
  margin: 15px;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;

  color: #383838;
`;

const NotificationBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 477px;
  height: 500px;
  margin-left: -407px;
  box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  z-index: 5;

  @media only screen and (max-width: ${(props) =>
    props.theme.firstBreakpoint}px) {
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
    margin-left: 0px;
  ;
`;

const NotificationContainer = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: transparent;
      background-color: lightgrey;
    }
  }
`;

const SpinnerWrapperFetchMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CenterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 70%;
  height: 100%;
`;

const EmailSettingsBox = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: solid 1px #a7a6a6;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
`;

const SettingsIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
