import create from "zustand";

export const useStore = create(() => ({
  myCourseNavSelection: {
    university: "",
    courses: [],
  },
  sCourseNavSelectedUni: { name: "" },
  ScourseNavQueryFilter: { name: "" },
  ScourseNavSearchText: "",
  startChat: {
    toggleChat: false,
    type: null,
    chatTitle: null,
    receiverId: null,
    chatId: null,
    profilePicture: null,
    unreadMessagesCount: null,
  },

  // increasePopulation: () => set(state => ({ bears: state.bears + 1 })),
  // removeAllBears: () => set({ bears: 0 })
}));
