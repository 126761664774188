import arrow_back from "../../../../img/Arrow_Vector.svg";
import { ChatProfilePicture } from "./chat-profile-picture";
import React, { useLayoutEffect, useRef, useEffect } from "react";
import styled from "styled-components";
import { AddIcon } from "../../../icons/icons-svg/add-icon";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_USER_CHATS } from "../../../../apollo-client/queries";
import { MARK_CHAT_MESSAGES_AS_READ } from "../../../../apollo-client/mutations";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useStore } from "../../../../store/store";

export const SmallChatBar = ({ chosenChat, setChosenChat, searchContact }) => {
  const client = useApolloClient();
  const [isChatOpen, setIsChatOpen] = useQueryParam("ischatopen", BooleanParam);
  const {
    data: userChatsData,
    loading: loadingChats,
    fetchMore: fetchMoreChats,
  } = useQuery(GET_USER_CHATS, {
    variables: { search: searchContact, limit: 99 },
  });

  const chatsData = userChatsData?.user?.chats;

  const scrollContactsRef = useRef(null);

  const startChat = useStore((state) => state.startChat);

  const handleFetchMoreContacts = async () => {
    const { current } = scrollContactsRef;

    if (
      !loadingChats &&
      chatsData.chats.length < chatsData.chatsCount &&
      current.scrollTop + current.clientHeight >= current.scrollHeight
    ) {
      await fetchMoreChats({
        variables: {
          search: searchContact,
          limit: 99,
          skip: chatsData.chats.length,
        },
      });
    }
  };

  // refetch chats when searching
  useEffect(async () => {
    await client.refetchQueries({ include: [GET_USER_CHATS] });
  }, [searchContact]);

  const [markChatMessagesAsRead] = useMutation(MARK_CHAT_MESSAGES_AS_READ);
  const markMessagesAsRead = async (chosenChatId) => {
    await markChatMessagesAsRead({
      variables: {
        id: chosenChatId || chosenChat.chatId,
      },
    });
  };

  // new chat data loaded
  useEffect(async () => {
    const updatedChosenChat = userChatsData?.user?.chats.chats.find(
      (chat) => chat._id === chosenChat.chatId
    );

    if (updatedChosenChat && updatedChosenChat._id !== chosenChat.chatId) {
      setChosenChat({
        type: updatedChosenChat.type,
        chatTitle: updatedChosenChat.title,
        profilePicture:
          updatedChosenChat.picture ||
          updatedChosenChat.otherPrivateChatMember.profilePicture,
        receiverId: getId(updatedChosenChat),
        chatId: updatedChosenChat._id,
        unreadMessagesCount: updatedChosenChat.unreadMessagesCount,
        isOnVacation: updatedChosenChat.otherPrivateChatMember.onHoliday,
        isBlockedBy: updatedChosenChat.isBlockedBy,
      });
    }
  }, [userChatsData]);

  const getId = (chat) => {
    if (chat.type === "group") return chat._id;
    else return chat.otherPrivateChatMember._id;
  };

  // reload chosenChat if it was updated
  useLayoutEffect(() => {
    if (chatsData?.chats?.[0]) {
      const chats = chatsData?.chats;

      setChosenChat({
        type: chats[0].type,
        chatTitle: chats[0].title,
        profilePicture:
          chats[0].picture || chats[0].otherPrivateChatMember.profilePicture,
        receiverId: getId(chats[0]),
        chatId: chats[0]._id,
        unreadMessagesCount: chats[0].unreadMessagesCount,
        isOnVacation: chats[0].otherPrivateChatMember.onHoliday,
        isBlockedBy: chats[0].isBlockedBy,
      });
      // setIsFirstContactsLoading(false);
    }
  }, [chatsData]);

  //start new teacher chat
  useEffect(() => {
    if (
      startChat &&
      startChat.toggleChat &&
      chosenChat.receiverId !== startChat.receiverId
    ) {
      const existingChat = chatsData?.chats.find(
        (chat) => chat.title === startChat.chatTitle
      );
      if (existingChat) {
        setChosenChat({
          type: existingChat.type,
          chatTitle: existingChat.title,
          receiverId: existingChat.otherPrivateChatMember._id,
          chatId: existingChat._id,
          profilePicture: existingChat.otherPrivateChatMember.profilePicture,
          unreadMessagesCount: existingChat.unreadMessagesCount,
          isOnVacation: existingChat.otherPrivateChatMember?.onHoliday,
          isBlockedBy: existingChat.isBlockedBy,
        });
      } else {
        setChosenChat({
          type: startChat.type,
          chatTitle: startChat.chatTitle,
          receiverId: startChat.receiverId,
          chatId: startChat.chatId,
          profilePicture: startChat.profilePicture,
          unreadMessagesCount: startChat.unreadMessagesCount,
          isOnVacation: startChat.otherPrivateChatMember?.onHoliday,
          isBlockedBy: startChat.isBlockedBy,
        });
      }
    }
    if (startChat.toggleChat && isChatOpen !== true) {
      setIsChatOpen(true);
    }
  }, [startChat]);

  return (
    <Container>
      <ArrowBackContainer
        onClick={() => {
          setIsChatOpen(!isChatOpen);
          useStore.setState({
            startChat: {
              toggleChat: false,
            },
          });
        }}
      >
        <ArrowBack src={arrow_back} isChatOpen={isChatOpen} />
      </ArrowBackContainer>
      {
        // !isFirstContactsLoading &&
        chosenChat.chatTitle && (
          <PictureWrapper onClick={() => setIsChatOpen(true)}>
            <ChatProfilePicture
              src={chosenChat.profilePicture}
              chatTitle={chosenChat.chatTitle || ""}
              unreadMessagesCount={chosenChat.unreadMessagesCount}
              chosenChatIsOpen={isChatOpen}
              width={"60px"}
              height={"60px"}
              onClick={async () => {
                if (chosenChat.unreadMessagesCount > 0) {
                  await markMessagesAsRead(chosenChat._id);
                }
              }}
              isBlocked={chosenChat?.isBlockedBy?.length > 0}
            />
          </PictureWrapper>
        )
      }
      <ContactsWrapper
        ref={scrollContactsRef}
        onScroll={handleFetchMoreContacts}
        onClick={() => setIsChatOpen(true)}
      >
        {
          // !isFirstContactsLoading &&

          userChatsData &&
            chatsData.chats
              .filter((chat) => chat._id !== chosenChat.chatId)
              .map((chat, i) => (
                <PictureWrapper key={"PictureWrapper_" + i}>
                  <ChatProfilePicture
                    key={"ChatProfilePicture_" + i}
                    src={
                      chat.picture || chat.otherPrivateChatMember.profilePicture
                    }
                    chatTitle={chat.title}
                    unreadMessagesCount={chat.unreadMessagesCount}
                    onClick={async () => {
                      // setFirstNewMessageId(null);
                      // getMessages();
                      if (chat.unreadMessagesCount > 0) {
                        await markMessagesAsRead(chat._id);
                      }
                      // if (isTeachingRequest) setIsTeachingRequest(false);
                      setChosenChat({
                        type: chat.type,
                        chatTitle:
                          chat.title || chat.otherPrivateChatMember.username,
                        receiverId: getId(chat),
                        chatId: chat._id,
                        profilePicture:
                          chat.picture ||
                          chat.otherPrivateChatMember.profilePicture,
                        unreadMessagesCount: 0,
                        isOnVacation: chat.otherPrivateChatMember.onHoliday,
                        isBlockedBy: chat.isBlockedBy,
                      });
                      // setChatChanged(true);
                      // focusInput();
                    }}
                    width={"60px"}
                    height={"60px"}
                    isBlocked={chat?.isBlockedBy?.length > 0}
                  />
                </PictureWrapper>
              ))
        }
        {/*not used,    is display: none */}
        <StyledAddIcon color={"#C23D3E"} />
      </ContactsWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 93px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArrowBackContainer = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PictureWrapper = styled.div`
  width: 90px;
  margin-top: 45px;

  &:first-child {
    margin-top: 29px;
  }
`;

const StyledAddIcon = styled(AddIcon)`
  display: none;
  margin-top: 5px;
  cursor: pointer;
`;

const ArrowBack = styled("img")`
  margin-top: 20px;
  transform: ${(props) => (props.isChatOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s;
`;

const ContactsWrapper = styled.div`
  margin-top: 15px;
  width: 90px;
  border-right: 1px solid #c4c4c4;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: transparent;
      background-color: ${(props) => props.theme.scrollBarColor};
    }
  }
`;
