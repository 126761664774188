import React, { useState, Fragment, useRef } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { FormattedMessage } from "react-intl";

import { useMutation } from "@apollo/client";
import { UNBECOME_TEACHER } from "../../../apollo-client/mutations";
import { GET_USER_TEACHINGS } from "../../../apollo-client/queries/teaching-queries";
import { Link } from "react-router-dom";
import { useOutsideMenuCloserWithFunction } from "../../../helpers";
import { EditTeachingDialog } from "../../teachers-page/dialogs/edit-teaching-dialog";
import { ConfirmationDialog } from "../../../components/dialogs/confirmation-dialog";
import { useOpenClose } from "../../../hooks/use-open-close";
import { BecomeTeacherDialog } from "../../teachers-page/dialogs/become-teacher-dialog";
import { Tooltip } from "../../../components/common/tooltip/tooltip";
import { GET_TEACHINGS } from "../../../apollo-client/queries";

/* This component will be displayde in three different forms
- isActive: true
- isActive: false
- isBlocked
*/

export const TutoringCourse = ({ title, teaching, user }) => {
  const [isTeacherEditOpen, setIsTeacherEditMenuOpen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const courseId = teaching.course._id;
  const teachingId = teaching._id;

  const [unbecomeTeacher] = useMutation(UNBECOME_TEACHER, {
    refetchQueries: [GET_TEACHINGS, "teachings", GET_USER_TEACHINGS],
  });

  const editMenuRef = useRef(null);
  useOutsideMenuCloserWithFunction(editMenuRef, () => {
    setIsEditMenuOpen(false);
  });

  const { value: isDeactivateDialogOpen, toggle: toggleDeactivateDialogOpen } =
    useOpenClose(false);

  const { value: isDeleteDialogOpen, toggle: toggleIsDeleteDialogOpen } =
    useOpenClose(false);

  const { value: isActivateDialogOpen, toggle: toggleIsActivateDialogOpen } =
    useOpenClose(false);

  const openTeacherEdit = () => {
    setIsEditMenuOpen(false);
    setIsTeacherEditMenuOpen(true);
  };

  const deactivateTeaching = async () => {
    await unbecomeTeacher({ variables: { course: courseId } });
  };
  const deleteTeaching = async () => {
    await unbecomeTeacher({ variables: { course: courseId, isDeleted: true } });
  };

  return (
    <Fragment>
      <ConfirmationDialog
        title={
          <FormattedMessage id="teacher.details.profile.deactivate-dialog.title" />
        }
        content={
          <FormattedMessage
            id={"teacher.details.profile.deactivate-dialog.description"}
          />
        }
        isOpen={isDeactivateDialogOpen}
        toggle={toggleDeactivateDialogOpen}
        action={deactivateTeaching}
      />

      <ConfirmationDialog
        title={
          <FormattedMessage id="teacher.details.profile.delete-dialog.title" />
        }
        content={
          <FormattedMessage
            id={"teacher.details.profile.delete-dialog.description"}
          />
        }
        isOpen={isDeleteDialogOpen}
        toggle={toggleIsDeleteDialogOpen}
        action={deleteTeaching}
      />

      <BecomeTeacherDialog
        closeDialog={toggleIsActivateDialogOpen}
        isDialogOpen={isActivateDialogOpen}
        user={user}
        courseId={courseId}
        inactiveTeaching={teaching}
      />

      <Container>
        <CourseTitleLink
          to={`/courses/${courseId}/teachers/${
            teaching.isActive ? teachingId : ""
          }`}
          isactive={teaching.isActive.toString()}
          isBlocked={teaching?.isBlocked?.toString()}
        >
          {title}
        </CourseTitleLink>
        <MoreOptionsWrapper>
          {!teaching.isActive && (
            <SmallDeactivatedText>
              <FormattedMessage id={"teacher.details.profile.deactivated"} />
            </SmallDeactivatedText>
          )}

          {teaching.isBlocked && (
            <Tooltip
              tooltipText={
                <FormattedMessage
                  id={"teacher.details.profile.blocked.myprofile.tooltip"}
                />
              }
            >
              <SmallBlockedText>
                <FormattedMessage id={"teacher.details.profile.blocked"} />
              </SmallBlockedText>
            </Tooltip>
          )}

          {!teaching.isBlocked && (
            <StyledIoIosArrowDown
              onClick={() => setIsEditMenuOpen(!isEditMenuOpen)}
              aria-controls="simple-menu"
              aria-haspopup="true"
            />
          )}
          {isEditMenuOpen && (
            <DropdownBox ref={editMenuRef}>
              <DropdownText onClick={openTeacherEdit}>
                <FormattedMessage id={"general.edit"} />
              </DropdownText>
              {teaching.isActive && (
                <DropdownText onClick={toggleDeactivateDialogOpen}>
                  <FormattedMessage id={"teacher.details.profile.deactivate"} />
                </DropdownText>
              )}
              {!teaching.isActive && (
                <DropdownText onClick={toggleIsActivateDialogOpen}>
                  <FormattedMessage id={"teacher.details.profile.activate"} />
                </DropdownText>
              )}
              <DropdownText onClick={toggleIsDeleteDialogOpen}>
                <FormattedMessage id={"teacher.details.profile.delete"} />
              </DropdownText>
            </DropdownBox>
          )}
        </MoreOptionsWrapper>
      </Container>
      <EditTeachingDialog
        teaching={teaching}
        isDialogOpen={isTeacherEditOpen}
        closeDialog={() => setIsTeacherEditMenuOpen(false)}
      />
    </Fragment>
  );
};

const MoreOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const SmallDeactivatedText = styled.div`
  font-size: 12px;
  color: #d2cece;
  margin-right: 10px;
  font-weight: 600;
`;
const SmallBlockedText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.primaryColor};
  margin-right: 10px;
  font-weight: 600;
`;

const StyledIoIosArrowDown = styled(IoIosArrowDown)`
  min-width: 20px;
  min-height: 30px;
  color: #747474;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  width: 70%;
  min-width: 250px;

  border-bottom: 0.5px solid #555555;

  &:last-child {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    width: 95%;
  }
`;

const CourseTitleLink = styled(Link)`
  white-space: nowrap;
  text-overflow: "hidden";

  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;

  text-decoration: none;
  color: ${(props) => (props.isactive === "true" ? "#555555" : "#D2CECE")};
  color: ${(props) => (props.isBlocked === "true" ? "#D2CECE" : "#555555")};

  &:hover {
    color: ${(props) => props.theme.primaryAccentColor};
  }
`;

const DropdownBox = styled.div`
  width: 200px;
  position: absolute;
  display: flex;
  flex-direction: column;

  margin-left: -180px;
  box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  z-index: 1;
`;

const DropdownText = styled.div`
  font-size: 18px;
  margin-left: 20px;
  cursor: pointer;
  margin-bottom: 15px;
  color: ${(props) => props.theme.beforeHoverTextColor};

  &:first-child {
    margin-top: 15px;
  }

  &:hover {
    color: ${(props) => props.theme.hoverTextColor};
  }
`;
