import { gql } from "@apollo/client";

export const GET_TEACHING = gql`
  query getTeaching($_id: String) {
    teachings(_id: $_id) {
      teachings {
        teacher {
          username
          profilePicture
        }
      }
    }
  }
`;
