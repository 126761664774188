import { gql } from "@apollo/client";

export const MESSAGE_SUBSCRIPTION = gql`
  subscription messagePosted {
    messagePosted {
      _id
      messagesCount
      unreadMessagesCount
      messages {
        _id
        chat
        chatType
        author {
          _id
          username
          profilePicture
        }
        isAuthor
        message
        wasDelivered
        wasRead
        wasReadByMe
        createdAt
      }
    }
  }
`;

export const NEW_MESSAGE_STATE_SUBSCRIPTION = gql`
  subscription newMessageState {
    newMessageState {
      _id
      chat
      isAuthor
      message
      wasDelivered
      wasRead
    }
  }
`;

export const TEACHERS_SUBSCRIPTION = gql`
  subscription newTeaching {
    newTeaching {
      course {
        title
      }
      teacher {
        username
      }
    }
  }
`;

export const VIDEOS_SUBSCRIPTION = gql`
  subscription newVideo {
    newVideo {
      title
      description
      isContributor
      contributor {
        username
      }
      comments {
        comment
        contributor {
          username
        }
      }
    }
  }
`;

export const LINK_SUBSCRIPTION = gql`
  subscription newVideo {
    newLink {
      title
      description
      isContributor
      contributor {
        username
      }
      comments {
        comment
        contributor {
          username
        }
      }
    }
  }
`;

export const QUESTION_SUBSCRIPTION = gql`
  subscription newQuestion {
    newQuestion {
      title
      question
      isContributor
      comments {
        comment
        contributor {
          username
        }
      }
      contributor {
        username
      }
    }
  }
`;

export const COMMENT_SUBSCRIPTION = gql`
  subscription newComment {
    newComment {
      isContributor
      comment
      contributor {
        username
      }
    }
  }
`;

export const ANSWER_SUBSCRIPTION = gql`
  subscription newComment {
    newAnswer {
      isContributor
      contributor {
        username
      }
    }
  }
`;

export const GROUP_SUBSCRIPTION = gql`
  subscription newGroup {
    newGroup {
      title
      members {
        username
      }
      isPublic
    }
  }
`;

// export const NOTIFICATION_SUBSCRIPTION = gql`
//   subscription newNotification {
//     newNotification {
//       _id
//       contentId
//       notificationType
//       course {
//         _id
//         title
//         university
//       }
//       wasSeen
//       createdAt
//     }
//   }
// `;
